import React, { useContext } from 'react';
import Landing from './steps/00_landing';
import Results from './results';
import Form from './form';
import '../styles/ms-wiz.css';
import { WizCtx } from '../store/wizardContext';

export const Wiz = () => {
    const [globalState] = useContext(WizCtx);
    return (
        <React.Fragment>

            <Landing/>
            {globalState.done ? (
                ''
            ) : (
                <div className="ms-form-div">
                    <Form/>
                </div>
            )}
            <Results/>

        </React.Fragment>
    );
};
export default Wiz;
