/* eslint-disable no-console */
import React, { useContext } from 'react';
import { WizCtx } from '../../store/wizardContext';
import MSValidations from '../validation';
// eslint-disable-next-line max-len
import { FETCH_ERROR, FETCH_SUCCESS, BEGIN, VALIDATION_ERROR } from '../../store/reducers';
import Config from '../../configuration';
import ReactGA from 'react-ga4';
import gaEventData from '../eventData';

const c = new Config();

const msv = new MSValidations();

const eventTrack = (eventObject) => {
    ReactGA.event({
        category: eventObject.category,
        action: eventObject.action,
        label: eventObject.label, // optional
        value: eventObject.value, // optional, must be a number
    });
};


const handleNext = (state, dispatch) => {
    const errStr = validate(state, dispatch);
    if (errStr !== '') {
        return;
    }

    // need to tell the backend about status
    state.formValues.done = state.done;

    // deep copy the state object
    const currentState = JSON.parse(JSON.stringify(state));

    const gaEventObject = gaEventData(state);

    state.stepsCompleted.push(state.currentStep);
    state.currentStep = state.currentStep + 1;

    // chec skip sensitivity after the age question and age is 5
    if (state.currentStep === 2 && state.formValues.age === '5') {
        // skip sensitivity
        state.stepsCompleted.push(state.currentStep);
        state.currentStep = state.currentStep + 1;
        state.formValues.sensitivity = 'false';
    }

    // if sensitivity is set to false skip the step
    if (state.currentStep === 3 && state.formValues.sensitivity === 'false') {
        // track event since this leaf is going to en
        state.stepsCompleted.push(state.currentStep);
        state.currentStep = state.currentStep + 1;
        state.error = {};
        dispatch({
            type: FETCH_SUCCESS,
            payload: state,
        });
        eventTrack(gaEventObject);
        return;
    }


    // if age group is 3 or 4
    if ((state.currentStep === 3 && state.formValues.sensitivity === 'true') &&
        (state.formValues.age === '3' || state.formValues.age === '4')) {
        state.stepsCompleted.push(state.currentStep);
        state.currentStep = state.currentStep + 1;
        state.error = {};
        // state.formValues.sensitivityOption = '1';
    }

    // Making step five: whey dom balance question disappear
    if (state.currentStep === 5) {
        state.stepsCompleted.push(state.currentStep);
        state.currentStep = state.currentStep + 1;
    }

    if (state.nextStepArray.length > 0) {
        state.currentStep = state.nextStepArray.shift();
    }

    if (state.stepsCompleted.length > 9) {
        state.formValues.done = true;
        state.done = true;
    }

    fetch(c.API_URL, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'text/plain',
        },
        body: JSON.stringify(state.formValues),
    })
        .then(((response) => {
            if (!response.ok) {
                gaEventObject.label.errorFound = true;
                gaEventObject.label.message = 'Unable to get a response from the milkseed engine';

                throw Error('Unable to get a response from the milkseed engine');
            }

            return response.json();
        }))
        .then((json) => {
            state.result.formulas = json.formulas;
            if (state.done !== true) {
                state.done = json.done;
            }
            state.error = {};

            // gaEventObject.label.surveyCompleted = state.done;
            // gaEventObject.label.errorFound = state.error.found;
            eventTrack(gaEventObject);
            dispatch({
                type: FETCH_SUCCESS,
                payload: state,
            });
        }).catch((error) => {
            console.log(error);
            currentState.error = {
                found: true,
                type: 'API ERROR',
                msg: 'Unable to get a response from the milkseed engine',
            };
            eventTrack(gaEventObject);
            dispatch({
                type: FETCH_ERROR,
                payload: currentState,
            });
        });
};


const NextButton = () => {
    const [globalState, dispatch] = useContext(WizCtx);
    const scLen = globalState.stepsCompleted.length;
    const currentStep = globalState.currentStep;

    if (globalState.done) {
        return null;
    }

    if (currentStep > 0 && scLen < 9) {
        return (
            <button
                className="ms-button"
                type="button"
                onClick={() => handleNext(globalState, dispatch)}
            >
                NEXT
            </button>
        );
    } else if (scLen >= 9) {
        // TODO: fix this for dynamic steps
        return (
            <button
                className="ms-button"
                type="button"
                onClick={() => handleNext(globalState, dispatch)}
            >
                SUBMIT
            </button>
        );
    } else if (currentStep === 0) {
        return (
            <button
                className="ms-button"
                type="button"
                onClick={() => handleNext(globalState, dispatch)}
            >
                BEGIN
            </button>

        );
    }
};

const validate = (state, dispatch) => {
    let name; let value;
    switch (state.currentStep) {
        case 0:
            dispatch({ type: BEGIN });
            return;
        case 1:
            name = 'age';
            value = state.formValues.age;
            break;
        case 2:
            name = 'sensitivity';
            value = state.formValues.sensitivity;
            break;
        case 3:
            name = 'sensitivityOption';
            value = state.formValues.sensitivityOption;
            break;
        case 4:
            name = 'base';
            value = state.formValues.base;
            break;
        case 5:
            name = 'dom';
            value = state.formValues.dom;
            break;
        case 6:
            name = 'importance';
            value = state.formValues.imp;
            break;
        case 7:
            name = 'ozMade';
            value = state.formValues.ozMade;
            break;
        case 9:
            name = 'ppt';
            value = state.formValues.sensitivity;
            break;
        case 8:
            name = 'organic';
            value = state.formValues.organic;
            break;
        default:
            return;
    }

    const errStr = msv.validate(name, value);
    if (errStr !== '') {
        state.error = {
            found: true,
            type: 'validation error',
            msg: errStr,
        };
        dispatch({
            type: VALIDATION_ERROR,
            payload: state,
        });
    }
    return errStr;
};

export default NextButton;
