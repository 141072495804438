/* eslint-disable max-len */

export const AgeOptions = [
    {
        value: '1',
        text: '0-6 Months',
    },
    {
        value: '2',
        text: '6-12 Months',
    },
    {
        value: '3',
        text: '1-2 Years',
    },
    {
        value: '4',
        text: '2-3 Years',
    },
    {
        value: '5',
        text: '3+ Years',
    },
];

export const SenstivityOptions = [
    {
        value: '1',
        text: 'Allergy (cow\'s milk)',
        ttText: 'In all allergies, the immune system reacts to trigger molecules (allergens). Your immune system produces antibodies that detect the allergen, causing inflammatory reactions and the release of a chemical called histamine, both of which cause allergic symptoms.In the case of milk, the trigger molecules are two milk proteins called whey and casein. You or your child may be allergic to either one of these proteins, or both.',
    },
    {
        value: '2',
        text: 'Colic',
        ttText: 'Colic is regular, unexplained crying fits that usually last for at least three hours. The cause is unknown, but theories include immaturity of the bowel, food allergies and ‘gas’ or ‘wind’',
    },
    {
        value: '3',
        text: 'Constipation',
        ttText: 'Constipation is the passing of hard, dry bowel motions (stools) that may be infrequent or difficult to pass. The most common causes of constipation include a change in routine, not enough fibre in the daily diet, not enough fluids and lack of exercise.',
    },
    {
        value: '4',
        text: 'Lactose intolerance',
        ttText: 'Lactose is a milk sugar that is broken down by the enzyme lactase, which is found in the small intestine. Lactose intolerance is the reduced ability to digest milk sugars, due to insufficient amounts of the gut enzyme called lactase.',
    },
    {
        value: '5',
        text: 'Regurgitation',
        ttText: 'Regurgitation, or reflux, involves bringing the contents of the stomach back up into the food pipe or mouth.',
    },
    {
        value: '6',
        text: 'Hydrolysed protein',
        ttText: 'The formula protein is broken down to help make it easy to digest',
    },
];

export const BaseOptions = [
    {
        value: 'cow',
        text: 'Cow',
        ttText: 'The most common formula base available',
    },
    {
        value: 'goat',
        text: 'Goat',
        ttText: 'A newer alternative, proposed by their manufacturers to be easier to digest and absorb',
    },
    {
        value: 'soy',
        text: 'Soy',
        ttText: 'A feeding choice for vegan families and babies with specific medical conditions',
    },
    {
        value: 'sheep',
        text: 'Sheep',
        ttText: 'A newer alternative, proposed by their manufacturers to be easier to digest and absorb',
    },
];

export const OzMadeOptions = [
    {
        value: 'true',
        text: 'Yes',
    },
    {
        value: 'false',
        text: 'No',
    },
    {
        value: 'no-preference',
        text: 'I don\'t have a preference',
    },
];

export const OrganicOptions = [
    {
        value: 'true',
        text: 'Yes, organic only',
    },
    {
        value: 'false',
        text: 'No, avoid organic',
    },
    {
        value: 'no-preference',
        text: 'I\'m happy with either',
    },
];

export const PricePerTinOptions = [
    {
        value: '1',
        text: 'Under $20',
    },
    {
        value: '2',
        text: 'Between $20-$30',
    },
    {
        value: '3',
        text: 'Over $30',
    },
    {
        value: '4',
        text: 'I don\'t have a preference',
    },
];

export const PricePerTinOptions234 = [
    {
        value: '2',
        text: 'Between $20-$30',
    },
    {
        value: '3',
        text: 'Over $30',
    },
    {
        value: '4',
        text: 'I don\'t have a preference',
    },
];

