/* eslint-disable max-len */
import React, { useContext } from 'react';
import { WizCtx } from '../../store/wizardContext';
import WizTT from './tooltip';
import RadioGroup from './field';

const options = [
    {
        value: 'true',
        text: 'Yes',
    },
    {
        value: 'false',
        text: 'No',
    },
];

const Sensitivity = () => {
    const [globalState, dispatch] = useContext(WizCtx);
    if (globalState.currentStep !== 2) {
        return null;
    }
    return (
        <React.Fragment>
            <div className="ms-wiz-step">
                <h3 className="ms-teal-text ms-page-large-text">SPECIALTY FORMULAS</h3>
                <p>Some infant formulas contain a modified composition to help address </p>
                <p>allergies and sensitivities</p>
                <div className="ms-text-tt">
                    <label className="ms-step-question">
                        Do you require a sensitivity-specific formula?{' '}
                    </label>
                    <WizTT ttText={['Conditions may include lactose', ' ',
                        'intolerance, milk allergy, colic, reflux, and constipation']
                    } />
                </div>
            </div>
            <div className="ms-step-field-div">
                <RadioGroup
                    name={'sensitivity'}
                    sv={globalState.formValues.sensitivity}
                    options={options}
                    dispatch={dispatch}
                />
            </div>
        </React.Fragment>
    );
};

export default Sensitivity;
