import React, { useContext } from 'react';
import { FETCH_SUCCESS } from '../store/reducers';
import { WizCtx } from '../store/wizardContext';

const handleNext = (state, dispatch) => {
    state.isFBMOpen = true;
    dispatch({
        type: FETCH_SUCCESS,
        payload: state,
    });
};

const FeedbackButton = () => {
    const [globalState, dispatch] = useContext(WizCtx);
    if (globalState.done) {
        return (
            <button
                className="ms-button"
                type="button"
                onClick={() => handleNext(globalState, dispatch)}
            >
                GIVE FEEDBACK
            </button>
        );
    } else {
        return null;
    }
};

export default FeedbackButton;
