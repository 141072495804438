/* eslint-disable max-len */
import React, {useContext} from 'react';
import {WizCtx} from '../../store/wizardContext';
import RadioGroup from './field';

const Dom = () => {
    const [globalState, dispatch] = useContext(WizCtx);
    if (globalState.currentStep !== 5) {
        return null;
    }

    const options = [
        {
            value: 'true',
            text: 'Yes',
        },
        {
            value: 'false',
            text: 'No',
        },
    ];

    return (
        <React.Fragment>
            <div className="ms-wiz-step">
                <h3 className="ms-teal-text ms-page-large-text">WHEY BALANCE</h3>
                <p>Whey is the main protein in human breast milk and many manufacturers</p>
                <p>add in extra whey to better mimic human milk composition</p>
                <div className="ms-text-tt">
                    <label className="ms-step-question">
                        Would you prefer a whey-balanced formula?
                    </label>
                </div>
            </div>
            <div className="ms-step-field-div">
                <RadioGroup
                    name={'dom'}
                    sv={globalState.formValues.dom}
                    options={options}
                    dispatch={dispatch}
                />
            </div>
        </React.Fragment>
    );
};
export default Dom;
