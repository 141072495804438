import {
    AgeOptions,
    SenstivityOptions,
    OzMadeOptions,
    OrganicOptions,
    PricePerTinOptions,
} from "./steps/constants";

const gaEventData = (state) => {

    const formValues = state.formValues;
    const eo = {
        category: 'default',

        action: 'default',
        label: 'default',
        value: 0,
    };


    // used in switch below
    const preferenceRouter = (option) => {
        switch (option) {
            case 'true':
                return 0;
            case 'false':
                return 1;
            default:
                return 2;
        }
    };

    switch (state.currentStep) {
        case 0:
            eo.category = "Landing Page";
            eo.action = "Survey Begins";
            return eo;
        case 1:
            eo.category = "Age Selection";
            eo.action = "Age Group";
            eo.value = Number(formValues.age);
            eo.label = "AgeGroup: " + AgeOptions[formValues.age - 1].text;
            // eo.label = {
            //     'ageGroup': AgeOptions[formValues.age - 1].text,
            //     'surveyCompleted': state.done,
            // };
            return eo;
        case 2:
            eo.category = "Senstivity Selection";
            eo.action = "Senstivity";
            eo.value = formValues.sensitivity ? 1 : 0;
            eo.label = "Senstivity: " + formValues.sensitivity;
            // eo.label = {
            //     'senstivity': formValues.sensitivity,
            //     'surveyCompleted': state.done,
            //     'errorFound': state.error.found,
            // };
            return eo;
        case 3:
            eo.category = "Senstivity Options";
            eo.action = "Senstivity Option";
            eo.value = Number(formValues.sensitivityOption);
            eo.label = "SenstivityOption" + SenstivityOptions[formValues.sensitivityOption - 1].text;
            // eo.label = {
            //     'senstivityOption': SenstivityOptions[formValues.sensitivityOption - 1].text,
            //     'surveyCompleted': state.done,
            //     'errorFound': state.error.found,
            // };
            return eo;
        case 4:
            eo.category = "Base Selection";
            eo.action = "Milk Base";
            eo.value = Number(formValues.age);
            eo.label = "milkBase: " + formValues.base;
            // eslint-disable-next-line no-case-declarations
            // eo.label = {
            //     'milkBase': formValues.base,
            //     'surveyCompleted': state.done,
            //     'errorFound': state.error.found,
            // };
            return eo;
        case 5:
            eo.category = "Whey Balance";
            eo.action = "Whey Balance";
            eo.value = formValues.sensitivity ? 1 : 0;
            eo.label = "preferWheyBalance: " + formValues.sensitivity ? 'Yes' : 'No';
            // eo.label = {
            //     "preferWheyBalance": formValues.sensitivity ? 'Yes' : 'No',
            //     'surveyCompleted': state.done,
            //     'errorFound': state.error.found,
            // };
            return eo;
        case 6:
            eo.category = "Importance";
            eo.action = "Ranking";
            eo.label = JSON.stringify(formValues.imp);
            // eo.label = JSON.parse(JSON.stringify(formValues.imp));
            return eo;
        case 7:
            eo.category = "OzMade";
            eo.action = "AusMade";
            eo.label = 'ozMadePreferred: ' + OzMadeOptions[preferenceRouter(formValues.OzMade)];
            // eo.label = {
            //     'ozMadePreferred': OzMadeOptions[preferenceRouter(formValues.OzMade)],
            //     'surveyCompleted': state.done,
            //     'errorFound': state.error.found,
            // };
            return eo;
        case 8:
            eo.category = "Organic";
            eo.action = "Organic";
            eo.label = 'organiceFormula: ' + OrganicOptions[preferenceRouter(formValues.OzMade)];
            // eo.label = {
            //     'organiceFormula': OrganicOptions[preferenceRouter(formValues.OzMade)],
            //     'surveyCompleted': state.done,
            //     'errorFound': state.error.found,
            // };
            return eo;
        case 9:
            eo.category = "Price Per Tin";
            eo.action = "PricePerTin";
            eo.value = Number(formValues.ppt);
            eo.label = 'PreferedPricePerTin: ' + PricePerTinOptions[formValues.ppt - 1];
            // eo.label = {
            //     'PreferedPricePerTin': PricePerTinOptions[formValues.ppt - 1],
            //     'surveyCompleted': state.done,
            //     'errorFound': state.error.found,
            // };
            return eo;
        default:
            return eo;
    }
};

export default gaEventData;
