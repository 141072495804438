/* eslint-disable max-len */
import React, { useContext } from 'react';
import { WizCtx } from '../../store/wizardContext';
import RadioGroup from './field';
import { BaseOptions } from './constants';

const Base = () => {
    const [globalState, dispatch] = useContext(WizCtx);
    if (globalState.currentStep !== 4) {
        return null;
    }

    let baseOptions = BaseOptions;
    if (globalState.formValues.age === '3' || globalState.formValues.age === '4' || globalState.formValues.age === '5') {
        baseOptions = BaseOptions.slice(0, 2);
    }
    return (
        <React.Fragment>
            <div className="ms-wiz-step">
                <h3 className="ms-teal-text ms-page-large-text">INFANT FORMULA BASE </h3>
                <p>Infant formulas can be made using components from </p>
                <p>cow&apos;s milk, goat&apos;s milk, or soy beans</p>
                <div className="ms-text-tt">
                    <label className="ms-step-question">
                        Do you have a preferred formula base?
                    </label>
                </div>
            </div>
            <div className="ms-step-field-div">
                <RadioGroup
                    name={'base'}
                    sv={globalState.formValues.base}
                    options={baseOptions}
                    ttText={'The most common formula base available'}
                    dispatch={dispatch}
                />
            </div>
        </React.Fragment>
    );
};

export default Base;
