
import React, { useContext } from 'react';
import { WizCtx } from '../store/wizardContext';
import { FB_CHANGE, FETCH_SUCCESS, FETCH_ERROR } from '../store/reducers';
import '../styles/modal.css';
import Config from '../configuration';
const c = new Config();

const handleFB = (state, dispatch) => {
    const fb = '{ "feedback" : "' + state.feedback + '"}';

    fetch(c.API_URL + '/feedback', {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'text/plain',
        },
        body: JSON.stringify(JSON.parse(fb)),
    })
        .then(((response) => {
            if (!response.ok) {
                throw Error('Unable to get a response from the engine');
            }
            return response.json();
        }))
        .then((json) => {
            state.error = {};
            state.isFBMOpen = false;
            dispatch({
                type: FETCH_SUCCESS,
                payload: state,
            });
        }).catch((error) => {
            state.error = {
                found: true,
                type: 'API ERROR',
                msg: 'Unable to get a response from the engine',
            };
            dispatch({
                type: FETCH_ERROR,
                payload: state,
            });
        });
};

const closeFBM = (state, dispatch) => {
    state.isFBMOpen = false;
    dispatch({
        type: FETCH_SUCCESS,
        payload: state,
    });
};
const FeedackModal = () => {
    const [globalState, dispatch] = useContext(WizCtx);
    if (!globalState.done || !globalState.isFBMOpen) {
        return null;
    }
    return (
        <React.Fragment>
            <div className="ms-modal">
                <div className="ms-modal-wrapper fb-modal">
                    <div className="ms-modal-content">
                        <div className="modal-header">
                            <span></span>
                            <button className="modal-header-close" type="button" name="close"
                                onClick={(e) => closeFBM(globalState, dispatch)}><b>X</b></button>
                        </div>
                        <br></br>
                        <p>Please provide feedback to help us improve the experience</p>
                        <br></br>
                        <form acceptCharset="UTF-8">
                            <textarea placeholder="Please write your suggestions here..." name="feedback"
                                rows="5" cols="50" onChange={(e) => dispatch({ type: FB_CHANGE, payload: e.target })}>
                            </textarea>
                            <button className="ms-button" type="button" name="feedbackForm"
                                value="Send Message" onClick={() => handleFB(globalState, dispatch)}>Submit </button>

                        </form>
                    </div>

                </div>
            </div>
        </React.Fragment>
    );
};

export default FeedackModal;
