import React, { useContext } from 'react';
import { WizCtx } from '../store/wizardContext';

const FormErrors = () => {
    const [globalState] = useContext(WizCtx);
    if (!globalState.error.found) {
        return null;
    }
    return (
        <React.Fragment>
            <div className="ms-field">
                <span className="ms-error-text">{globalState.error.msg}</span>
            </div>
        </React.Fragment>
    );
};
export default FormErrors;
