/* eslint-disable no-tabs */
import React, { useContext } from 'react';
import { WizCtx } from '../../store/wizardContext';
import NextButton from './nextButton';

const Landing = () => {
    const [globalState] = useContext(WizCtx);
    if (globalState.currentStep !== 0) {
        return null;
    }
    return (
        <React.Fragment>
            <div className="ms-wiz-step">
                <h3 className="ms-teal-text ms-page-large-text">WELCOME</h3>
                <br />
                <h3 className="ms-teal-text ms-page-smaller-text">
                    DISCOVER THE BEST INFANT FORMULAS FOR YOUR LITTLE ONE
                </h3>
                <span className="ms-page-smaller-text">
                    Answer a few simple questions and we will match
                    you with the most suitable infant formula to help your child thrive
                </span>
                <br />
                <div className="ms-button-div">
                    <NextButton />
                </div>
            </div>

        </React.Fragment>
    );
};
export default Landing;
