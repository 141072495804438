import React, { useContext } from 'react';
import { WizCtx } from '../store/wizardContext';
import '../styles/results.css';
import { MobileView, BrowserView } from 'react-device-detect';
import FeedackModal from './feedbackModal';
import FeedbackButton from './feedbackButton';
import StartOverButton from './startOverButton';
import ReactGA from "react-ga4";


const eventTrack = (eventObject) => {
    ReactGA.event({
        category: eventObject.category,
        action: eventObject.action,
        label: eventObject.label, // optional
        value: eventObject.value, // optional, must be a number
    });
};

const Results = () => {
    const [globalState] = useContext(WizCtx);
    if (!globalState.done) {
        return null;
    }
    function ucFirst(string) {
        return (string.charAt(0).toUpperCase() + string.slice(1));
    }
    function yesNo(bool) {
        return bool ? 'Yes' : 'No';
    }

    function dollars(price) {
        if (price <= 1) {
            return '$';
        } else if (price === 2) {
            return '$$';
        } else {
            return '$$$';
        }
    }
    const eo = {
        category: 'Result Page',
        action: 'Survey complete',
        label: 'default',
        value: 0,
    };

    if (globalState.result.formulas == null) {
        eo.label = {
            'resultsFound': 'no',
        };
        eventTrack(eo);
        return (
            <React.Fragment>
                <div className="ms-results">
                    <p className="ms-page-smaller-text">
                        No results matching your search criteria.
                    </p>
                </div>
                <div className="ms-button-div results-button">
                    <StartOverButton />
                    <FeedbackButton />
                </div>
                <FeedackModal />
            </React.Fragment>
        );
    }
    const formulas = globalState.result.formulas;

    eo.label = {
        'resultsFound': 'yes',
        'productsReturned': formulas,
    };
    eventTrack(eo);
    return (
        <React.Fragment>
            <MobileView>
                <div className="ms-results">
                    <h1 className="ms-teal-text ms-page-large-text">YOUR PERSONALISED RESULTS</h1>
                    <p className="ms-page-smaller-text">
                        These infant formulas are bested suited to match the needs and requirements of your family.
                        <br />
                        Say goodbye to uncertainty and embrace feeling confident when you purchase infant
                        formula - these are a great fit for you and your little one&apos;s feeding journey!</p>
                    {formulas.map((formula) => (
                        <div key={formula.product_id}>
                            <table key={formula.product_id} id="products">
                                <thead>
                                    <tr style={{ borderStyle: 'hidden' }}>
                                        <td style={{ borderLeftStyle: 'hidden', textAlign: 'center' }}
                                            colSpan={2}>
                                            <img className="msimage"
                                                src={'/static/img/' + formula.product_id + '.jpg'}
                                                alt={formula.name} />

                                        </td>
                                    </tr>
                                </thead>
                                <thead>
                                    <tr>
                                        <th colSpan={2}>{formula.name}</th>
                                    </tr>
                                </thead>
                                <tbody>


                                    <tr>
                                        <td><strong>Stage (Months)</strong></td>
                                        <td key={formula.product_id}>{formula.months}</td>
                                    </tr>
                                    <tr>
                                        <td><strong>formula Base</strong></td>
                                        <td key={formula.product_id}>
                                            {ucFirst(formula.base)}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td><strong>Sensitivity</strong></td>
                                        <td key={formula.product_id}>
                                            {ucFirst(formula.sensitivity)}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td><strong>Organic Manufacture</strong></td>
                                        <td key={formula.product_id}>
                                            {yesNo(formula.organic)}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td><strong>Australian Made</strong></td>
                                        <td key={formula.product_id}>
                                            {yesNo(formula.aus_made)}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td><strong>Price ($ - $$$)</strong></td>
                                        <td key={formula.product_id}>
                                            {dollars(formula.price)}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td key={formula.product_id}>
                                            <button
                                                className="ms-button"
                                                type="button"
                                                onClick={() =>
                                                    window.open(formula.buy_now, '_blank', 'noopener,noreferrer')}
                                            >
                                                BUY NOW
                                            </button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>

                        </div>
                    ))}
                </div>
                <div className="ms-button-div results-button">
                    <StartOverButton />
                    <FeedbackButton />
                </div>
                <FeedackModal />
            </MobileView>
            <BrowserView>
                <FeedackModal />
                <div className="ms-results">
                    <h3 className="ms-teal-text ms-results-large-text">
                        YOUR PERSONALISED RESULTS
                    </h3>
                    <br></br>
                    <p className="ms-page-smaller-text">
                        These infant formulas are bested suited to match the needs and requirements of your family.
                        <br />
                        Say goodbye to uncertainty and embrace feeling confident when you purchase infant
                        formula - these are a great fit for you and your little one&apos;s feeding journey!
                    </p>

                    <br></br>
                    <table id="products">
                        <thead>
                            <tr style={{ borderStyle: 'hidden' }}>
                                <td style={{ borderLeftStyle: 'hidden' }}></td>
                                {formulas.map((formula) => (
                                    <td key={formula.name}
                                        style={{
                                            borderLeftStyle: 'hidden',
                                            textAlign: 'center',
                                        }}>
                                        <img className="msimage"
                                            src={'/static/img/' + formula.product_id + '.jpg'}
                                            alt={formula.name} />
                                    </td>
                                ))}
                            </tr>
                            <tr>
                                <th></th>
                                {formulas.map((formula) => (
                                    <th key={formula.product_id} >
                                        {ucFirst(formula.manufacturer)}
                                        <br />{ucFirst(formula.name)}
                                    </th>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td><strong>Stage (Months)</strong></td>
                                {formulas.map((formula) => (
                                    <td key={formula.product_id}>{formula.months}</td>
                                ))}
                            </tr>
                            <tr>
                                <td><strong>Formula Base</strong></td>
                                {formulas.map((formula) => (
                                    <td key={formula.product_id}>
                                        {ucFirst(formula.base)}
                                    </td>
                                ))}
                            </tr>
                            <tr>
                                <td><strong>Sensitivity</strong></td>
                                {formulas.map((formula) => (
                                    <td key={formula.product_id}>
                                        {ucFirst(formula.sensitivity)}
                                    </td>
                                ))}
                            </tr>
                            <tr>
                                <td><strong>Organic Manufacture</strong></td>
                                {formulas.map((formula) => (
                                    <td key={formula.product_id}>
                                        {yesNo(formula.organic)}
                                    </td>
                                ))}
                            </tr>
                            <tr>
                                <td><strong>Australian Made</strong></td>
                                {formulas.map((formula) => (
                                    <td key={formula.product_id}>
                                        {yesNo(formula.aus_made)}
                                    </td>
                                ))}
                            </tr>
                            <tr>
                                <td><strong>Price ($ - $$$)</strong></td>
                                {formulas.map((formula) => (
                                    <td key={formula.product_id}>
                                        {dollars(formula.price)}
                                    </td>
                                ))}
                            </tr>
                            <tr>
                                <td></td>
                                {formulas.map((formula) => (
                                    <td key={formula.product_id}>
                                        <button
                                            className="ms-button"
                                            type="button"
                                            onClick={() =>
                                                window.open(formula.buy_now, '_blank', 'noopener,noreferrer')}
                                        >
                                            BUY NOW
                                        </button>
                                    </td>
                                ))}
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div className="ms-button-div results-button">
                    <StartOverButton />
                    <FeedbackButton />
                </div>
            </BrowserView>
        </React.Fragment >
    );
};

export default Results;
