import React from 'react';
import Tooltip from 'react-png-tooltip';

const WizTT = (props) => {
    if (props.ttText === null || props.ttText === '' || props.ttText === undefined) {
        return null;
    }
    return (
        <React.Fragment>
            <Tooltip key={props.name} className='ms-tooltip'
                tooltip={
                    <img
                        className="helper-text"
                        src="/static/i2.png"
                        alt="helper-text-icon"
                        border="0"
                    />
                }
            >
                <span className="tooltipcss">
                    <p>{props.ttText}</p>
                </span>
            </Tooltip>
        </React.Fragment>
    );
};

export default WizTT;
