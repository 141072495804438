/* eslint-disable max-len */
import React, { useContext } from 'react';
import { WizCtx } from '../../store/wizardContext';
import WizTT from './tooltip';
import RadioGroup from './field';
import { OrganicOptions } from './constants';

const Organic = () => {
    const [globalState, dispatch] = useContext(WizCtx);
    if (globalState.currentStep !== 8) {
        return null;
    }
    return (
        <React.Fragment>
            <div className="ms-wiz-step">
                <h3 className="ms-teal-text ms-page-large-text">ORGANIC</h3>
                <div className="ms-text-tt">
                    <label className="ms-step-question">
                        Would you like an organically produced formula?{' '}
                    </label>
                    <WizTT
                        ttText={'Organically produced infant formula is free from chemically formulated fertilizers, growth stimulants, antibiotics, or pesticides'} />
                </div>
            </div>
            <div className="ms-step-field-div">
                <RadioGroup
                    name={'organic'}
                    sv={globalState.formValues.organic}
                    options={OrganicOptions}
                    dispatch={dispatch} />
            </div>
        </React.Fragment>
    );
};

export default Organic;
