import React, { useEffect, useRef } from 'react';
import { useCookies } from 'react-cookie';
import {
    disableBodyScroll,
    enableBodyScroll,
    clearAllBodyScrollLocks,
} from 'body-scroll-lock';
import './styles/modal.css';

const Modal = () => {
    const [cookies, setCookie] = useCookies(['msDisclaimer']);
    const modalRef = useRef(null);

    useEffect(() => {
        if (modalRef.current) {
            if (cookies.msDisclaimer === undefined || cookies.msDisclaimer === '') {
                disableBodyScroll(modalRef.current, {
                    allowTouchMove: (element) => element.id === 'no-scroll-lock',
                });
            } else {
                // enable when closed
                enableBodyScroll(modalRef.current);
                clearAllBodyScrollLocks();
            }
        }

    }, [cookies]);

    function setDisclaimer() {
        setCookie('msDisclaimer', true, { path: '/' });
    }

    if (cookies.msDisclaimer === 'true') {
        document.body.style.overflow = 'unset';
        return null;
    }

    return (
        <div className="ms-modal">
            <div className="ms-modal-wrapper">
                <div className="ms-modal-content">
                    <h3>Welcome</h3>
                    <br></br>
                    <p>This website contains information about infant formula</p>
                    <br></br>
                    <p>The World Health Organisation recommends that breastfeeding
                        is best for your baby as it provides a balanced diet with all the nutrients
                        required for a growing baby. Breastfeeding has many advantages to both mother and child,
                        including transfer of antibodies to fight infection.</p>
                    <br />
                    <p>If you’re thinking about using infant formula to feed your baby,
                        you should seek professional medical guidance first.
                        Using a combination of breast milk and infant formula,
                        or infant formula exclusively for an extended time,
                        may reduce your own production of breast milk and
                        reversing this may be difficult.</p>
                    <br />
                    <p>Infant formula should be prepared and used as directed.
                        Unnecessary or improper use, such as the use of unboiled water,
                        unboiled bottles or incorrect dilution may present a health hazard.
                        The social and financial implications, such as the preparation requirements
                        and the cost of providing formula until 12 months of age, should also be considered.</p>
                    <br />
                    <p>By clicking “I agree”, you understand that Milk Seed is supplying publicly available
                        information about infant formula only. No information provided by
                        Milk Seed is deemed medical advice. Milk Seed claims no responsibility to
                        any person or entity for any liability, loss, or damage caused or alleged to be
                        caused directly or indirectly as a result of the use, application,
                        or interpretation of the information presented.</p>
                    <button className="ms-button" onClick={setDisclaimer}>I Agree</button>
                </div>
            </div>
        </div>
    );
};

export default Modal;
