import React, { useReducer } from 'react';
// eslint-disable-next-line max-len
import wizReducer from './reducers';
import { WizCtx } from './wizardContext';
import { v4 as uuid } from 'uuid';
import ReactGA from "react-ga4";

const initialiseState = () => {
    const s = {
        stepsCompleted: [0],
        currentStep: 0, // Default is Step 0
        done: false,
        nextStepArray: [],
        formValues: {
            guid: '',
            age: '',
            sensitivity: '',
            sensitivityOption: '',
            base: '',
            dom: '',
            imp: {
                price: '',
                origin: '',
                organic: '',
            },
            ozMade: '',
            ppt: '',
            organic: '',
        },
        result: {
            formulas: null,
        },
        error: {
            found: false,
            type: '',
            msg: '',
        },
        isFBMOpen: false,
    };
    s.formValues.guid = uuid();
    return s;
};

const WizProvider = (props) => {

    const [state, dispatch] = useReducer(wizReducer, initialiseState());
    ReactGA.set({ userId: state.formValues.guid });
    return (
        <WizCtx.Provider value={[state, dispatch]}>
            {props.children}
        </WizCtx.Provider>
    );
};

export default WizProvider;
