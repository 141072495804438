import React from 'react';
import {OPTION_CHG} from '../../store/reducers';
import WizTT from './tooltip';

const RadioGroup = (props) => {
    return (
        <React.Fragment>
            <div className="ms-radio-div">
                <ul>
                    {props.options.map((opt) => (
                        <li key={props.name+opt.value}>
                            <input
                                id={props.name + opt.value }
                                key={ props.name + opt.value }
                                type="radio"
                                name={props.name}
                                value={opt.value}
                                checked={props.sv === opt.value}
                                onChange={(e) => props.dispatch({type: OPTION_CHG, payload: e.target})}
                            />
                            <label htmlFor={props.name + opt.value } className="radio">{opt.text}</label>
                            <WizTT ttText={opt.ttText}/>
                            <div className="check"></div>
                        </li>

                    ))}
                </ul>
            </div>
        </React.Fragment>
    );
};

export default RadioGroup;
