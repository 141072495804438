class MSValidations {
    validate(name, value) {
        const ageArray = ['1', '2', '3', '4', '5'];
        const baseArray = ['cow', 'goat', 'soy', 'sheep', 'no-preference'];
        const soArray = ['1', '2', '3', '4', '5', '6', '7', '8'];
        switch (name) {
        case 'age':
            if (value === '' || !(ageArray.indexOf(value) > -1)) {
                return 'Please select from one of the options above';
            }
            return '';
        case 'sensitivity':
            if ((value !== 'false') && (value !== 'true')) {
                return 'Please select if your infant is sensitive or not';
            }
            return '';
        case 'sensitivityOption':
            if (value === '' || !(soArray.indexOf(value) > -1)) {
                return 'Please select an option from above';
            }
            return '';
        case 'base':
            if (!(baseArray.indexOf(value) > -1)) {
                return 'Please select a formula base';
            }
            return '';
        case 'dom':
            if ((value !== 'false') && (value !== 'true')) {
                return 'Please select if you prefer whey-dominant formula';
            }
            return '';
        case 'ozMade':
            if ((value !== 'false') &&
                    (value !== 'true') &&
                    (value !== 'no-preference')) {
                return 'Please select if you prefer Australian made formula';
            }
            return '';
        case 'organic':
            if ((value !== 'false') &&
                    (value !== 'true') &&
                    (value !== 'no-preference')) {
                return 'Please select if you prefer organic formula';
            }
            return '';
        case 'importance':
            if ((value.price === '') ||
                    (value.organic === '') ||
                    (value.origin === '') ||
                    (new Set(Object.values(value))).size !== Object.keys(value).length) {
                return 'Please rank to select one low, one medium and one high response';
            }
            return '';
        default:
            return '';
        }
    }
}

export default MSValidations;
