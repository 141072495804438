/* eslint-disable max-len */
import React, { useContext } from 'react';
import { WizCtx } from '../../store/wizardContext';
import RadioGroup from './field';
import { SenstivityOptions } from './constants';
const Sensitivities = () => {
    const [globalState, dispatch] = useContext(WizCtx);

    if (globalState.currentStep !== 3 || globalState.formValues.sensitivity === '' || globalState.formValues.sensitivity === 'false') {
        return null;
    }
    return (
        <React.Fragment>
            <div className="ms-wiz-step">
                <h3 className="ms-teal-text ms-page-large-text">SPECIALTY FORMULAS</h3>
                <p>It is advised to consult with a healthcare professional before</p>
                <p>using a specialty formula, to ensure it is suitable for your infant</p>
                <div className="ms-text-tt">
                    <label className="ms-step-question">
                        Which specialty formula does your infant need?
                    </label>
                </div>
            </div>
            <div className="ms-step-field-div">
                <RadioGroup
                    name="sensitivityOption"
                    sv={globalState.formValues.sensitivityOption}
                    options={SenstivityOptions}
                    dispatch={dispatch}
                />
            </div>
        </React.Fragment>
    );
};

export default Sensitivities;
