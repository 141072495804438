// eslint-disable-next-line max-len


export const NEXT = 'NEXT';
export const PREV = 'PREV';
export const SUBMIT = 'SUBMIT';
export const BEGIN = 'BEGIN';
export const OPTION_CHG = 'OPTION_CHG';
export const SELECT_CHG = 'SELECT_CHG';
export const FETCH_ERROR = 'FETCH_ERROR';
export const FETCH_SUCCESS = 'FETCH_SUCCESS';
export const VALIDATION_ERROR = 'VALIDATION_ERROR';
export const FB_CHANGE = 'FB_CHANGE';
export const FLUSH = 'FLUSH';

const handleSelectChange = (eventTarget, state) => {
    const { name, value } = eventTarget;
    const fv = state.formValues;
    const nsArray = state.nextStepArray;

    if (value > 0) {
        switch (name) {
            case 'price':
                fv.imp.price = value;
                nsArray[value - 1] = 9;
                break;
            case 'organic':
                fv.imp.organic = value;
                nsArray[value - 1] = 8;
                break;
            case 'origin':
                fv.imp.origin = value;
                nsArray[value - 1] = 7;
                break;
            default:
                return;
        }
        return { ...state, nextStepArray: nsArray, formValues: fv };
    }
    return { ...state };
};
const handleOptionChange = (eventTarget, state) => {
    const { name, value } = eventTarget;
    const fv = state.formValues;
    fv[name] = value;
    return { ...state, formValues: fv };
};

const handleInputChange = (eventTarget, state) => {
    const { name, value } = eventTarget;
    state[name] = value;
    return { ...state };
};

// Find a better way to flush state
const flushState = () => {
    const s = {
        stepsCompleted: [0],
        currentStep: 1, // Default is Step 0
        done: false,
        nextStepArray: [],
        formValues: {
            guid: '',
            age: '',
            sensitivity: '',
            sensitivityOption: '',
            base: '',
            dom: '',
            imp: {
                price: '',
                origin: '',
                organic: '',
            },
            ozMade: '',
            ppt: '',
            organic: '',
        },
        result: {
            formulas: null,
        },
        error: {
            found: false,
            type: '',
            msg: '',
        },
        isFBMOpen: false,
    };
    return s;
};

const wizReducer = (state, action) => {
    switch (action.type) {
        case BEGIN:
            if (state.currentStep === 0) {
                state.currentStep = state.currentStep + 1;
            }
            return { ...state };
        case OPTION_CHG:
            return handleOptionChange(action.payload, state);
        case SELECT_CHG:
            return handleSelectChange(action.payload, state);
        case PREV:
            state = action.payload;
            return { ...state };
        case FETCH_ERROR:
            state = action.payload;
            return { ...state };
        case FETCH_SUCCESS:
            state = action.payload;
            return { ...state };
        case VALIDATION_ERROR:
            state = action.payload;
            return { ...state };
        case FB_CHANGE:
            return handleInputChange(action.payload, state);
        case FLUSH:
            return flushState();
        default:
            return state;
    }
};

export default wizReducer;
