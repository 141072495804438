import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import * as serviceWorker from './serviceWorker';
import ReactGA from "react-ga4";

ReactGA.initialize("G-ZVP1VJ31Q2", { gaOptions: { cookieFlags: "SameSite=None;Secure" } });

const root = createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <App />,
    </React.StrictMode>,
);

serviceWorker.unregister();
