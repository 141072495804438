import React, { useContext } from 'react';
import { WizCtx } from '../store/wizardContext';
import { FLUSH } from '../store/reducers';

const StartOverButton = () => {
    const [globalState, dispatch] = useContext(WizCtx);
    if (globalState.done) {
        return (
            <button
                className="ms-button"
                type="button"
                onClick={() => dispatch({ type: FLUSH })}
            >
                START OVER
            </button>
        );
    } else {
        return null;
    }
};

export default StartOverButton;
