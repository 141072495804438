/* eslint-disable max-len */
import React, {useContext} from 'react';
import {WizCtx} from '../../store/wizardContext';
import {SELECT_CHG} from '../../store/reducers';

const Importance = () => {
    const [globalState, dispatch] = useContext(WizCtx);
    if (globalState.currentStep !== 6) {
        return null;
    }

    return (
        <React.Fragment>
            <div className="ms-wiz-step">
                <h3 className="ms-teal-text ms-page-large-text">PERSONAL PREFERENCES</h3>
                <p>Over 25 infant formulas still meet your criteria.</p>
                <div className="ms-text-tt">
                    <label className="ms-step-question">
                    Please rank the importance of each option below to further narrow your matches
                    </label>
                </div>
            </div>
            <div className="ms-step-field-div">
                <SelectField
                    name={'price'}
                    text={'Price'}
                    dispatch={dispatch}
                    value={globalState.formValues.imp.price} />
                <SelectField
                    name={'origin'}
                    text={'Australian Made'}
                    dispatch={dispatch}
                    value={globalState.formValues.imp.origin} />
                <SelectField
                    name={'organic'}
                    text={'Organic Manufacture'}
                    dispatch={dispatch}
                    value={globalState.formValues.imp.organic} />
            </div>
        </React.Fragment>
    );
};

const SelectField = (props) => {
    return (
        <React.Fragment>
            <div className="ms-field-select">
                <label htmlFor={props.name} className="">
                    {props.text}
                </label>
                <select
                    value={props.value}
                    name={props.name}
                    onChange={(e) => props.dispatch({type: SELECT_CHG, payload: e.target})}>
                    <option value="0">Select</option>
                    <option value="1">High</option>
                    <option value="2">Medium</option>
                    <option value="3">Low</option>
                </select>

            </div>

        </React.Fragment>
    );
};

export default Importance;
