import React from 'react';
import image from './styles/logo.png';
import './styles/App.css';
import Wiz from './wizard/wizard';
import WizProvider from './store/store';
import Modal from './modal';

function App() {
    return (
        <React.Fragment>
            <Modal />
            <div className="ms-grid-container">
                <div className="ms-nav-bar">
                    <div className="ms-logo-href">
                        <a href="/">
                            <p className="ms-nav-text-item">
                                <img className="" src={image} alt="Logo" />
                                Milk Seed
                            </p>
                        </a>
                    </div>
                </div>
                <div className="ms-section">
                    <div className="ms-wizard-top"></div>
                    <div className="ms-wizard">
                        <WizProvider>
                            <Wiz />
                        </WizProvider>
                    </div>
                    <div className="ms-wizard-bottom"></div>
                </div>
                <div className="ms-footer">
                    <div className="ms-footer-body">
                        <span>
                            Important Notice to Parents and Guardians:
                            The World Health Organisation recommends that
                            breastfeeding is best for your baby.
                            Baby formulas should only be used after you have
                            sought advice from a doctor or health practitioner.
                            A decision not to breastfeed can be difficult
                            to reverse and introducing partial bottle
                            feeding may reduce the supply of breast milk.
                        </span>
                    </div>
                    <div className="ms-footer-top">
                        <a href="https://www.facebook.com/MilkSeedCo"
                            className="facebook social">
                            <img className="ms-social-icon" src="./static/fb512.png" alt="facebook" />
                        </a>
                        <a href="https://www.linkedin.com/company/milkseed"
                            className="linkedin social">
                            <img className="ms-social-icon" src="./static/li.svg" alt="linkedin" />
                        </a>
                    </div>
                </div>
            </div>
        </React.Fragment>

    );
}
export default App;
