import React, { useContext } from 'react';
import Age from './steps/01_age';
import Sensitivity from './steps/02_sensitivity';
import Sensitivities from './steps/03_sensitivity_options';
import Base from './steps/04_base';
import Dom from './steps/05_dom';
import OzMade from './steps/07_ozMade';
import PricePerTin from './steps/09_pricepertin';
import Organic from './steps/08_organic';
import Importance from './steps/06_importance';
import FormErrors from './errors';
import PrevButton from './steps/prevButton';
import NextButton from './steps/nextButton';
import { WizCtx } from '../store/wizardContext';


const Form = () => {
    const [globalState] = useContext(WizCtx);
    if (globalState.currentStep === 0 || globalState.done === true) {
        return null;
    }
    return (
        <React.Fragment>
            <form onSubmit={globalState.handleSubmit}>
                <Age />
                <Sensitivity />
                <Sensitivities />
                <Base />
                <Dom />
                <Importance />
                <OzMade />
                <PricePerTin />
                <Organic />
                <FormErrors />
                <div className="ms-button-div">
                    <PrevButton />
                    <NextButton />
                </div>
            </form>
        </React.Fragment>
    );
};
export default Form;
