/* eslint-disable react/no-unescaped-entities */
/* eslint-disable max-len */
import React, { useContext } from 'react';
import { WizCtx } from '../../store/wizardContext';
import WizTT from './tooltip';
import RadioGroup from './field';
import { AgeOptions } from './constants';

const Age = () => {
    const [globalState, dispatch] = useContext(WizCtx);
    if (globalState.currentStep !== 1) {
        return null;
    }
    return (
        <React.Fragment>
            <div className="ms-wiz-step">
                <h3 className="ms-teal-text ms-page-large-text">LET'S START WITH THE BASICS...</h3>
                {/* Below Line has Has Tooltip (Helper Text) */}
                <div className="ms-text-tt">
                    <label className="ms-step-question">
                        How old is your infant?{' '}
                    </label>
                    <WizTT
                        name={'age'}
                        ttText={['Infant age is important as the composition of infant formulas changes to best mimic breast milk as your baby grows.',
                            <br key='age' />,
                            'All infant formulas bought in Australia are safe to use for babies within the recommended age group written on the product.']
                        } />
                </div>
            </div>

            <RadioGroup
                name={'age'}
                sv={globalState.formValues.age}
                options={AgeOptions}
                dispatch={dispatch}
            />
        </React.Fragment>
    );
};

export default Age;
