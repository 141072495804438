import React, { useContext } from 'react';
import { WizCtx } from '../../store/wizardContext';
import { PREV } from '../../store/reducers';

const prevAction = (state, dispatch) => {

    if (state.currentStep > 6) {
        state.nextStepArray.unshift(state.currentStep);
    }

    const step = state.stepsCompleted.pop();

    if (state.currentStep <= 1) {
        state.currentStep = 1;
    } else {
        state.currentStep = step;
    }

    // sensitivity question
    if (state.currentStep === 3 && state.formValues.sensitivity === 'false') {
        state.currentStep = state.stepsCompleted.pop();
        if (state.formValues.age === '5') {
            state.currentStep = state.stepsCompleted.pop();
        }
    }

    if (step === 5) {
        state.currentStep = state.stepsCompleted.pop();
    }

    dispatch({ type: PREV, payload: state });
};

const PrevButton = () => {
    const [globalState, dispatch] = useContext(WizCtx);
    const currentStep = globalState.currentStep;

    if (globalState.done) {
        return null;
    }
    if (currentStep !== 1 && currentStep !== 0) {
        return (
            <React.Fragment>
                <button className="ms-button"
                    type="button"
                    onClick={() => prevAction(globalState, dispatch)}
                >
                    BACK
                </button>
            </React.Fragment>
        );
    } else {
        return null;
    }
};

export default PrevButton;
