/* eslint-disable max-len */
import React, { useContext } from 'react';
import { WizCtx } from '../../store/wizardContext';
import RadioGroup from './field';
import { PricePerTinOptions, PricePerTinOptions234 } from './constants';

const PricePerTin = () => {
    const [globalState, dispatch] = useContext(WizCtx);
    if (globalState.currentStep !== 9) {
        return null;
    }
    let pptOptions = PricePerTinOptions;
    if (globalState.formValues.organic === 'true' || globalState.formValues.ozMade === 'true') {
        pptOptions = PricePerTinOptions234;
    }
    return (
        <React.Fragment>
            <div className="ms-wiz-step">
                <h3 className="ms-teal-text ms-page-large-text">PRICE</h3>
                <div className="ms-text-tt">
                    <label className="ms-step-question">
                        What is your preferred price per tin?
                    </label>
                </div>
            </div>
            <div className="ms-step-field-div">
                <RadioGroup
                    name={'ppt'}
                    sv={globalState.formValues.ppt}
                    options={pptOptions}
                    dispatch={dispatch} />
            </div>
        </React.Fragment>
    );
};

export default PricePerTin;
