/* eslint-disable max-len */
import React, { useContext } from 'react';
import { WizCtx } from '../../store/wizardContext';
import RadioGroup from './field';
import { OzMadeOptions } from './constants';
const OzMade = () => {
    const [globalState, dispatch] = useContext(WizCtx);
    if (globalState.currentStep !== 7) {
        return null;
    }
    return (
        <React.Fragment>
            <div className="ms-wiz-step">
                <h3 className="ms-teal-text ms-page-large-text">COUNTRY OF MANUFACTURE</h3>
                <div className="ms-text-tt">
                    <label className="ms-step-question">
                        Do you prefer formula that is made in Australia?
                    </label>
                </div>
            </div>
            <div className="ms-step-field-div">
                <RadioGroup
                    name={'ozMade'}
                    sv={globalState.formValues.ozMade}
                    options={OzMadeOptions}
                    dispatch={dispatch} />
            </div>
        </React.Fragment>
    );
};

export default OzMade;
